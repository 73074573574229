import React from "react";
import "./style.scss";
import { Fade } from "react-awesome-reveal";

const Step = ({ number, title, subtitle }) => {
  return (
    <div className="step">
      <span>{number}</span>
      <Fade direction="left" triggerOnce>
        <p className="title">{title}</p>
      </Fade>
      <Fade direction="left" triggerOnce duration={1200}>
        <p>{subtitle}</p>
      </Fade>
    </div>
  );
};

export default Step;
