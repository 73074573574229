import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import Logo from "../../assets/LogoMobile.svg";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

const NewsCard = ({ title, content, image, date, alias }) => {
  const [isHovered, setIsHovered] = useState(false);
  const newsCardRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleTouchStart = (event) => {
      if (!newsCardRef.current.contains(event.target)) {
        setIsHovered(false);
      }
    };

    document.addEventListener("touchstart", handleTouchStart);

    return () => {
      document.removeEventListener("touchstart", handleTouchStart);
    };
  }, [newsCardRef]);

  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
    navigate(`/news/${alias}`);
  };

  return (
    <div
      ref={newsCardRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onTouchStart={() => setIsHovered(true)}
      onTouchMove={() => setIsHovered(false)}
      onClick={handleNavigate}
      style={{
        backgroundImage: !isHovered
          ? `linear-gradient(to bottom, rgba(0, 0, 0, 0.01) 20%, rgba(255, 255, 255, 1) 70%), url(${image})`
          : `linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, rgba(255, 255, 255, 1) 110%), url(${image})`,
      }}
      className="newsCard"
    >
      <div className="newsContent">
        <p>{title}</p>
        <span dangerouslySetInnerHTML={{ __html: content }}></span>
      </div>
      <div className="newsDate">
        <img src={Logo} alt="Logo" />
        <span>{date}</span>
      </div>
    </div>
  );
};

export default NewsCard;
