import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./styles/main.scss";
import App from "./App";

import "./language/18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <Suspense fallback={<div>Loading...</div>}>
  <App />
  // </Suspense>
);
