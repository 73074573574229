import React from "react";
import "./style.scss";
import { Fade } from "react-awesome-reveal";

const Card = ({ number, text }) => {
  return (
    <div className="globalMarketCard">
      <Fade triggerOnce>
        <span>{number}+</span>
      </Fade>
      <Fade direction="left" triggerOnce>
        <p>{text}</p>
      </Fade>
    </div>
  );
};

export default Card;
