import React, { useEffect, useState } from "react";
import "./style.scss";
import Logo from "../../assets/Logo4.svg";
import Photo from "../../assets/Photo.png";
import Photo2 from "../../assets/Photo2.png";
import Photo3 from "../../assets/Photo3.png";
import TeamCard from "../../components/teamCard";
import BallBP2 from "../../assets/BallBP2.png";
import BallPY from "../../assets/BallPY.png";
import { GetMembers } from "../../api";
import { galleryImgURL, serverImgURL } from "../../helpers/image";
import { getMLValue } from "../../helpers/ml";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Fade } from "react-awesome-reveal";

const AboutUs = () => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    GetMembers().then(({ data }) => {
      setMembers(data);
    });
  }, []);

  const handleLinkClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const { t, i18n } = useTranslation();

  return (
    <div className="aboutUs">
      <img className="ball ball1-2" src={BallBP2} alt="img" />
      <img className="ball ball2-2" src={BallPY} alt="img2" />

      <NavLink
        className="navLinkF logoAboutUs"
        to="/"
        onClick={handleLinkClick}
      >
        <Fade delay={600} triggerOnce>
          <div>
            <img src={Logo} alt="LogoLatter" />
          </div>
        </Fade>
      </NavLink>

      <div className="about">
        <Fade delay={600} direction="down" triggerOnce>
          <span>{t("whoWeAre")}</span>
          <h1>{t("aboutSiriusCapital")}</h1>
          <h2>{t("siriusIsAGateway")}</h2>
        </Fade>
      </div>
      <div className="middle">
        <img src={Photo} alt="Photo" />
        <img className="photo2" src={Photo2} alt="Photo" />
        <img className="photo2 photo3" src={Photo3} alt="Photo" />
        <div className="text">
          <Fade delay={1000} duration={2500} triggerOnce>
            {t("techSpecialized")}
          </Fade>
        </div>
      </div>
      <div className="team">
        <Fade duration={2000} triggerOnce>
          <p className="title">{t("ourTeam")}</p>
        </Fade>
        <div className="cards">
          {members.length ? (
            members.map((member) => (
              <TeamCard
                key={member.id}
                portrait={galleryImgURL(member.image)}
                name={getMLValue(member.name, i18n.language)}
                jobTitle={getMLValue(member.role, i18n.language)}
                info={getMLValue(member.bio, i18n.language)}
              />
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
