import axios from "axios";
import { token } from "./token";

export const baseUrl =
  process.env.REACT_APP_SERVER_URL || "http://localhost:3333/";

export const instance = axios.create({
  baseURL: `${baseUrl}`,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

const configurator = async (config) => {
  config.headers.Authorization = `Bearer ${token.get()}`;
  return config;
};

instance.interceptors.request.use(configurator, console.info);
