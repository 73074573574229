class Token {
  field = "";

  constructor(field) {
    this.field = field;
  }

  set(value) {
    localStorage.setItem(this.field, value);
  }

  get() {
    return localStorage.getItem(this.field);
  }

  clear() {
    localStorage.removeItem(this.field);
  }
}

export const token = new Token("token");
