import i18n from "i18next";
import Backend from "i18next-http-backend";
import Languagedetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const domain = process.env.REACT_APP_COOKIE_DOMAIN;

i18n
  .use(Backend)
  .use(Languagedetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "am",
    // lng: "am", // default
    debug: false,
    detection: {
      order: ["cookie"],
      cookieDomain: domain,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
