import React from "react";
import "./style.scss";
import { Fade } from "react-awesome-reveal";

const Terms = ({ img, title, li1, li2, li3, li4, fromRight }) => {
  const direction = fromRight ? "right" : "left";
  return (
    <div className="termsCard">
      {img && <img src={img} alt="Img" />}
      <Fade triggerOnce direction="left">
        {title && <p>{title}</p>}
      </Fade>

      <ul>
        <Fade direction={direction} triggerOnce delay={100}>
          <li>
            <span>{li1}</span>
          </li>
        </Fade>
        <Fade direction={direction} triggerOnce delay={200}>
          <li>
            <span>{li2}</span>
          </li>
        </Fade>
        {li3 && (
          <Fade direction={direction} triggerOnce delay={300}>
            <li>
              <span>{li3}</span>
            </li>
          </Fade>
        )}
        {li4 && (
          <Fade direction={direction} triggerOnce delay={400}>
            <li>
              <span>{li4}</span>
            </li>
          </Fade>
        )}
      </ul>
    </div>
  );
};

export default Terms;
