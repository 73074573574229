import React from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";

const Loading = () => {
  const { t } = useTranslation();

  return (
    // <div className="loading">
    //   <img className="loadingD" src={LoadingCrcle} alt="LoadingCrcle" />
    //   <img className="loadingM" src={LoadingCrcleM} alt="LoadingCrcle" />

    //   <p>{t("loading")}</p>
    // </div>
    <div className="loading">
      <div className="loader-mobile"></div>
      <div className="loader"></div>
      <p>{t("loading")}</p>
    </div>
  );
};

export default Loading;
