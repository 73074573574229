import React from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { redirectToLogin, redirectToOnboarding } from "../../helpers/navigate";
import { Fade } from "react-awesome-reveal";

const Cards = ({ img, name, info }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="card">
      <img src={img} alt="" />
      <Fade direction="left" triggerOnce>
        <p>{name}</p>
      </Fade>
      <Fade duration={1200} direction="left" triggerOnce>
        <span>{info}</span>
      </Fade>
      <div className="buttons">
        <button onClick={redirectToLogin} className="cardLogin">
          {t("login")}
        </button>
        <button onClick={redirectToOnboarding}>{t("openAccount")}</button>
      </div>
    </div>
  );
};

export default Cards;
