import React from "react";
import "./style.scss";
import SKImg from "../../assets/SKImg.svg";
import Terms from "./../../components/terms";
import TermImg1 from "../../assets/TermImg1.svg";
import TermImg2 from "../../assets/TermImg2.svg";
import BallBG4 from "../../assets/BallBG4.png";
import BallPY from "../../assets/BallPY.png";
import { useTranslation } from "react-i18next";
import { Fade } from "react-awesome-reveal";

const SafeKeeping = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="armenianMarkets safeKeeping">
      <img className="ball ball6-1" src={BallBG4} alt="img" />
      <img className="ball ball6-2" src={BallPY} alt="img2" />
      <Fade
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        delay={600}
        direction="down"
        triggerOnce
      >
        <span>{t("custodySafeKeeping")}</span>
        <h1>{t("safeTrustworthy")}</h1>
        <h2>{t("necessaryScale")}</h2>
      </Fade>

      <img className="imgSC" src={SKImg} alt="SafeKeepingImg" />
      <div className="terms">
        <Terms
          img={TermImg1}
          title={t("safeKeepingAndCustody")}
          li1={t("siriusCapitalAuthorised")}
          li2={t("inPartnershipsWithTop")}
          li3={t("siriusCapitalProvides")}
        />
        <Terms
          img={TermImg2}
          title={t("clearing")}
          li1={t("clearingMember")}
          li2={t("highLevelsOf")}
        />
      </div>
    </div>
  );
};

export default SafeKeeping;
