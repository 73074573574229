import React, { useEffect, useState } from "react";
import "./style.scss";
import Card from "../../components/documentCard";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import Item from "../../components/accordion";
import BallBG2 from "../../assets/BallBG2.png";
import BallPY from "../../assets/BallPY.png";
import { useTranslation } from "react-i18next";
import { GetDocs } from "../../api";
import { getMLValue } from "./../../helpers/ml";
import { documentDownloadURL } from "../../helpers/doc";
import { Fade } from "react-awesome-reveal";

const DocumentsOld = () => {
  const [acordion, setAcordion] = useState(null);

  const { t, i18n } = useTranslation();

  const [docs, setDocs] = useState([]);
  const [kvartals, setKvartals] = useState({});

  useEffect(() => {
    GetDocs().then(({ data }) => {
      setDocs(data.filter((doc) => doc.year === null));
      setKvartals(
        groupBy(
          data.filter((doc) => doc.year !== null),
          "year"
        )
      );
    });
  }, []);

  const groupBy = (arr, key) => {
    const list = {};

    for (const doc of arr) {
      if (doc[key] in list) {
        list[doc[key]].push(doc);
      } else {
        list[doc[key]] = [doc];
      }
    }

    return list;
  };

  return (
    <div className="documents">
      <img className="ball ball4-1" src={BallBG2} alt="img" />
      <img className="ball ball4-2" src={BallPY} alt="img" />

      <Fade delay={600} direction="down" triggerOnce>
        <h1>{t("documentsOld")}</h1>
      </Fade>
      <div className="cardsDocument">
        {docs.map((doc) => (
          <Card
            key={doc.id}
            title={getMLValue(doc.name, i18n.language)}
            downloadUrl={documentDownloadURL(
              getMLValue(doc.files, i18n.language)
            )}
          />
        ))}
      </div>

      <Fade delay={600} direction="down" triggerOnce>
        <h2>{t("internalFinancialStatements")}</h2>
      </Fade>
      <div className="accordion">
        <Accordion transition transitionTimeout={250}>
          {Object.keys(kvartals)
            .sort((a, b) => b - a)
            .map((kvartal, index) => (
              <AccordionItem
                key={index}
                className="accordionItem"
                header={
                  <div
                    className="header"
                    onClick={() =>
                      setAcordion(acordion !== kvartal ? kvartal : null)
                    }
                  >
                    <Fade delay={600} direction="left" triggerOnce>
                      <p>{kvartal}</p>
                    </Fade>
                    <Fade delay={600} direction="left" triggerOnce>
                      <div
                        className={
                          acordion !== kvartal ? "close" : "close open"
                        }
                      ></div>
                    </Fade>
                  </div>
                }
              >
                {Object.values(kvartals[kvartal]).map((doc) => (
                  <Fade triggerOnce>
                    <Item
                      key={doc.id}
                      text={getMLValue(doc.name, i18n.language)}
                      downloadUrl={documentDownloadURL(
                        getMLValue(doc.files, i18n.language)
                      )}
                    />
                  </Fade>
                ))}
              </AccordionItem>
            ))}
        </Accordion>
      </div>
    </div>
  );
};

export default DocumentsOld;
