import React, { useEffect, useState } from "react";
import "./styel.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-creative";
import { Autoplay, Pagination, EffectCreative } from "swiper/modules";
import { useParams } from "react-router-dom";
import { GetNewsByAlias } from "../../api";
import { getMLValue } from "../../helpers/ml";
import { useTranslation } from "react-i18next";
import Loading from "../../components/loading";
import { newsImgURL } from "./../../helpers/image";
import { Fade } from "react-awesome-reveal";

const NewsPage = () => {
  const { id } = useParams();
  const { i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [newsData, setNewsData] = useState(null);
  useEffect(() => {
    GetNewsByAlias(id)
      .then(({ data }) => {
        if (data) {
          setNewsData(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (!newsData) {
    return <div className="newsPage">Post not found!</div>;
  }

  return (
    <div className="newsPage">
      <Fade style={{ width: "80%" }} delay={400} direction="down" triggerOnce>
        <h1>{getMLValue(newsData.title, i18n.language)}</h1>
        <span>Story by Sirius Capital • {newsData.date}</span>
      </Fade>

      <div className="newsContent">
        {newsData.news_images.length > 0 && (
          <Swiper
            modules={[Autoplay, Pagination, EffectCreative]}
            wrapperClass="newsImgWrapper"
            spaceBetween={24}
            slidesPerView={1}
            loop={true}
            autoplay={{
              delay: 5500,
              disableOnInteraction: false,
            }}
            // effect={"creative"}
            // creativeEffect={{
            //   prev: {
            //     shadow: true,
            //     translate: ["-120%", 0, -500],
            //   },
            //   next: {
            //     shadow: true,
            //     translate: ["120%", 0, -500],
            //   },
            // }}
            pagination={{ clickable: true }}
          >
            {newsData.news_images.map((i) => (
              <SwiperSlide key={i.id}>
                <img src={newsImgURL(i.news_image)} alt="News2" />
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        <Fade delay={400} direction="left" triggerOnce>
          <div
            className="descContent"
            style={{ marginTop: "10px" }}
            dangerouslySetInnerHTML={{
              __html: getMLValue(newsData.description, i18n.language),
            }}
          ></div>
        </Fade>
      </div>
    </div>
  );
};

export default NewsPage;
