import React, { useState } from "react";
import "./style.scss";
import Cookie from "../../assets/Cookie.svg";
import { useTranslation } from "react-i18next";

const Cookies = ({ onReject, onAccept }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="cookies">
      <div>
        <p>{t("cookie")}</p>
        <img src={Cookie} alt="Cookie" />
      </div>
      <div>
        <button onClick={onReject}>{t("reject")}</button>
        <button onClick={onAccept}>{t("onlyNecessary")}</button>
        <button onClick={onAccept}>{t("accept")}</button>
      </div>
    </div>
  );
};

export default Cookies;
