import React, { useEffect, useMemo, useState } from "react";
import "./style.scss";
import HomeImg from "../../assets/HomeImg.svg";
import Cards from "../../components/loginCard";
import Personal from "../../assets/AccPersonal.svg";
import Trading from "../../assets/AccTrading.svg";
import Step from "../../components/requestStep";
import Logo3 from "../../assets/Logo3.svg";
import BallBP from "../../assets/BallBP.png";
import BallPY from "../../assets/BallPY.png";
import BallBG from "../../assets/BallBG.png";
import BallPG from "../../assets/BallPG.png";
import VectorDown from "../../assets/VectorDown.svg";
import AppStore from "../../assets/AppStore.svg";
import GooglePlay from "../../assets/GooglePlay.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { redirectToLogin, redirectToOnboarding } from "../../helpers/navigate";
import { Fade } from "react-awesome-reveal";
import { GetExchangeRates } from "../../api";

const appUrl = process.env.REACT_APP_BASE_URL_OF_APP;

const Home = () => {
  const navigate = useNavigate();
  const handleLinkClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const { t, i18n } = useTranslation();
  const [amount, setAmount] = useState("");
  const [equivalent, setEquivalent] = useState("");

  const [exchangeRates, setExchangeRates] = useState([]);

  const [currencyList, setCurrencyList] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [currencyToList, setCurrencyToList] = useState([]);
  const [selectedToCurrency, setSelectedToCurrency] = useState("");

  const [lastChange, setLastChange] = useState("from");

  useEffect(() => {
    GetExchangeRates().then(({ data }) => {
      if (data) {
        setExchangeRates(data);
        setCurrencyList(
          Array.from(
            new Set([
              ...data.map((c) => c.tradeCurrencyCode),
              ...data.map((c) => c.currencyCode),
            ])
          )
        );
        setSelectedCurrency(data[0].tradeCurrencyCode);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedCurrency && exchangeRates) {
      const reverserList = Array.from(
        new Set([
          ...exchangeRates
            .filter((c) => c.tradeCurrencyCode === selectedCurrency)
            .map((c) => c.currencyCode),
          ...exchangeRates
            .filter((c) => c.currencyCode === selectedCurrency)
            .map((c) => c.tradeCurrencyCode),
        ])
      );

      setCurrencyToList(reverserList);
      setSelectedToCurrency(reverserList[0]);
    }
  }, [exchangeRates, selectedCurrency]);

  const onAmountEnter = (e) => {
    const strValue = String(e.target.value);
    const value = +strValue || "";
    setLastChange("from");
    setAmount(value);
  };
  const onequivalentEnter = (e) => {
    const strValue = String(e.target.value);
    const value = +strValue || "";
    setLastChange("to");
    setEquivalent(value);
  };

  const rate = useMemo(() => {
    return (
      exchangeRates.find(
        (r) =>
          r.tradeCurrencyCode === selectedCurrency &&
          r.currencyCode === selectedToCurrency
      ) || null
    );
  }, [selectedCurrency, selectedToCurrency]);

  const buyRate = useMemo(() => {
    return (
      exchangeRates.find(
        (r) =>
          r.tradeCurrencyCode === selectedToCurrency &&
          r.currencyCode === selectedCurrency
      ) || null
    );
  }, [selectedCurrency, selectedToCurrency]);

  useEffect(() => {
    if (amount && (rate || buyRate) && exchangeRates.length > 0) {
      if (rate) {
        if (lastChange === "to") {
          setAmount(+(equivalent / rate.sell).toFixed(4));
        } else {
          setEquivalent(+(amount * rate.sell).toFixed(4));
        }
      } else if (buyRate) {
        if (lastChange === "to") {
          setAmount(+(equivalent * buyRate.buy).toFixed(4));
        } else {
          setEquivalent(+(amount / buyRate.buy).toFixed(4));
        }
      } else {
        setEquivalent("");
        setAmount("");
      }
    } else {
      setEquivalent("");
      setAmount("");
    }
  }, [rate, buyRate, amount, equivalent, lastChange]);

  return (
    <div className="container">
      <img className="ball ball1" src={BallBP} alt="img" />
      <img className="ball ball2" src={BallPY} alt="img2" />
      <img className="ball ball3" src={BallBG} alt="img3" />
      <img className="ball ball4" src={BallPG} alt="img4" />

      <div className="home">
        <div className="introduction">
          <Fade delay={600} direction="down" triggerOnce>
            <h1>{t("inOneClick")}</h1>
            <h2>{t("StartInvestingFraction")}</h2>
            <div className="downloadFromStoreH">
              <a
                target="_blank"
                href="https://apps.apple.com/am/app/sirius-capital/id6475756282"
              >
                <img src={AppStore} alt="AppStore" />
              </a>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.siriuscapital"
              >
                <img src={GooglePlay} alt="GooglePlay" />
              </a>
            </div>

            <button onClick={redirectToOnboarding}>{t("openYourAcc")}</button>
          </Fade>
        </div>
        <Fade delay={600} triggerOnce>
          <img className="homeImg" src={HomeImg} alt="HomeImg" />
        </Fade>

        <Fade triggerOnce duration={2500}>
          <p className="subtitle">{t("simpler")}</p>
        </Fade>
        <div className="loginPart">
          <Cards
            img={Personal}
            name={t("PersFinanceAcc")}
            info={t("easyAccessInvestment")}
          />
          <Cards
            img={Trading}
            name={t("realTimeTrading")}
            info={t("allForAdvancedInvestors")}
          />
        </div>
        <div className="about">
          <div>
            <Fade direction="up" triggerOnce>
              <p>{t("free")}</p>
              <span>{t("accountOpening")}</span>
            </Fade>
          </div>
          <div>
            <Fade direction="up" triggerOnce>
              <p>0.15% </p>
              <div>
                <span>{t("commission")}</span>
                <span>{t("AMDBonus")}</span>
              </div>
            </Fade>
          </div>
          <div>
            <Fade direction="up" triggerOnce>
              <p>24/7</p>
              <span>{t("serviceFree")}</span>
            </Fade>
          </div>
        </div>

        <Fade triggerOnce duration={2500}>
          <p className="subtitle2">{t("requestToOpenAcc")}</p>
          <p className="subtitle3">{t("brokerageAssetManagement")}</p>
        </Fade>

        <div className="request">
          <Step
            number="1"
            title={t("fillOutTheApplication")}
            subtitle={t("onlyAPassport")}
          />
          <Step
            number="2"
            title={t("verification")}
            subtitle={t("contactAndVerify")}
          />
          <Step
            number="3"
            title={t("openAccount")}
            subtitle={t("accIsReadyToUse")}
          />
        </div>

        <div className="exchangeRateContainer">
          <div className="exchangeRate">
            <div className="exchangeRateHeader">
              <Fade delay={600} direction="left" triggerOnce>
                <h1>{t("exchangeRateToday")}</h1>
              </Fade>
              <Fade delay={900} direction="left" triggerOnce>
                <p>
                  {t("viewExchangeRate")}
                  {"   "}
                  <a href="/exchange-rates">{t("viewExchangeRateLink")}</a>
                </p>
              </Fade>
            </div>

            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>{t("buyEX")}</th>
                  <th>{t("sellEX")}</th>
                </tr>
              </thead>
              <tbody>
                {exchangeRates.map((rate, index) => (
                  <tr key={index}>
                    <td>{`${rate.tradeCurrencyCode}/${rate.currencyCode}`}</td>
                    <td>{rate.buy}</td>
                    <td>{rate.sell}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="exchangeRateCalculator">
            <div className="exchangeRateHeader">
              <Fade delay={600} direction="left" triggerOnce>
                <h1>{t("exchangeRateCalculator")}</h1>
              </Fade>
            </div>
            <div className="exchangeRateForm">
              <div className="exRateInput">
                <label htmlFor="have">{t("have")}</label>
                <input
                  id="have"
                  type={"number"}
                  value={amount}
                  onChange={onAmountEnter}
                  min={0}
                />
              </div>
              <div className="exRateCalcDropdown">
                <p>{selectedCurrency}</p>
                <img src={VectorDown} alt="VectorDown" />

                <ul className="exRateCalcDropdownContent">
                  {currencyList.map((c, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        setLastChange("from");
                        setSelectedCurrency(c);
                      }}
                    >
                      {c}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="exchangeRateForm">
              <div className="exRateInput">
                <label htmlFor="need">{t("need")}</label>
                <input
                  id="need"
                  type={"number"}
                  value={equivalent}
                  onChange={onequivalentEnter}
                  min={0}
                />
              </div>
              <div className="exRateCalcDropdown">
                <p>{selectedToCurrency}</p>
                <img src={VectorDown} alt="VectorDown" />

                <ul className="exRateCalcDropdownContent">
                  {currencyToList.map((c, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        setLastChange("to");
                        setSelectedToCurrency(c);
                      }}
                    >
                      {c}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="getStarted">
        <Fade triggerOnce>
          <NavLink className="navLinkF" to="/" onClick={handleLinkClick}>
            <div>
              <img src={Logo3} alt="LogoLetter" />
            </div>
          </NavLink>

          <p>{t("readyToGetStarted")}</p>
          <button onClick={redirectToOnboarding}>{t("openYourAcc")}</button>
        </Fade>
      </div>
    </div>
  );
};

export default Home;
