import React from "react";
import "./style.scss";
import BImg from "../../assets/BImg.svg";
import BImg2 from "../../assets/BImg2.svg";
import BImg3 from "../../assets/BImg3.svg";
import Step from "../../components/requestStep";
import Terms from "./../../components/terms";
import BallBP2 from "../../assets/BallBP2.png";
import BallPY from "../../assets/BallPY.png";
import BallBG3 from "../../assets/BallBG3.png";
import BallPG from "../../assets/BallPG.png";
import { useTranslation } from "react-i18next";
import { redirectToOnboarding } from "../../helpers/navigate";
import { baseUrl } from "../../api/instance";
import { Fade } from "react-awesome-reveal";

const Brokerage = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="armenianMarkets  brokerage">
      <img className="ball ball5-1" src={BallBP2} alt="img" />
      <img className="ball ball5-2" src={BallPY} alt="img2" />
      <img className="ball ball5-3" src={BallBG3} alt="img3" />
      <img className="ball ball5-4" src={BallPG} alt="img4" />

      <Fade
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        delay={600}
        direction="down"
        triggerOnce
      >
        <span>{t("brokerage")}</span>
        <h1>{t("exploreBrokerageAccount")}</h1>
        <h2>{t("tradeDiversely")}</h2>
      </Fade>
      <Fade triggerOnce>
        <img className="BImg" src={BImg} alt="SafeKeepingImg" />
      </Fade>

      <Fade triggerOnce duration={2500}>
        <p className="subtitle2">{t("requestToOpenAcc")}</p>
        <p className="subtitle3">{t("brokerageAssetManagement")}</p>
      </Fade>
      <div className="request">
        <Step
          number="1"
          title={t("fillOutTheApplication")}
          subtitle={t("onlyAPassport")}
        />
        <Step
          number="2"
          title={t("verification")}
          subtitle={t("contactAndVerify")}
        />
        <Step
          number="3"
          title={t("openAccount")}
          subtitle={t("accIsReadyToUse")}
        />
      </div>

      <Fade triggerOnce duration={2500}>
        <span>{t("readyToGetStarted")}</span>
      </Fade>
      <button onClick={redirectToOnboarding}>{t("openYourAcc")}</button>

      <div className="whatIsABrokerageAccount">
        <div className="whatIs">
          <Fade direction="left" triggerOnce>
            <p>{t("whatIsABrokerageAcc")}</p>
          </Fade>

          <Fade direction="left" delay={200} triggerOnce>
            <>
              <span>{t("brokerageAccIsInvestment")}</span>
              <a
                target="_blank"
                href={`${baseUrl}documents/Terms Of Brokerage Services.pdf`}
              >
                <i>{t("readTermsOfBusiness")}</i>
              </a>
            </>
          </Fade>
        </div>
        <Fade triggerOnce>
          <img className="brokerageImgs" src={BImg2} alt="BImg2" />
        </Fade>
      </div>

      <div className="whatIsABrokerageAccount whatCanYouDo">
        <Fade triggerOnce>
          <img className="brokerageImgs" src={BImg3} alt="BImg3" />
        </Fade>

        <div className="whatIs">
          <Fade direction="right" triggerOnce>
            <p>{t("withABrokerageAcc")}</p>
          </Fade>
          <Fade delay={200} direction="right" triggerOnce>
            <span>{t("youCanUseBrokerageAcc")}</span>
          </Fade>

          <Terms
            fromRight={true}
            li1={t("buyAndSellStocks")}
            li2={t("setAsideMoney")}
            li3={t("gainAccessTo")}
            // li2={t("takeAdvantage")}
            // li3={t("setAsideMoney")}
            // li4={t("gainAccessTo")}
            // "setAsideMoney": "Set aside money for your retirement, or other goals like college tuition or a down payment",
          />
        </div>
      </div>
    </div>
  );
};

export default Brokerage;
