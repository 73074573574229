import { instance } from "./instance";

export const GetMembers = async () => {
  try {
    const resp = await instance.get("members");
    return { data: resp.data.data };
  } catch (error) {
    return { data: [] };
  }
};

export const GetNews = async () => {
  try {
    const resp = await instance.get("news");
    return { data: resp.data.data };
  } catch (error) {
    return { data: [] };
  }
};

export const GetNewsByAlias = async (alias) => {
  try {
    const resp = await instance.get(`news/${alias}`);
    return { data: resp.data.data };
  } catch (error) {
    return { data: [] };
  }
};

export const GetResearch = async () => {
  try {
    const resp = await instance.get("research");
    return { data: resp.data.data };
  } catch (error) {
    return { data: [] };
  }
};

export const GetResearchByAlias = async (alias) => {
  try {
    const resp = await instance.get(`research/${alias}`);
    return { data: resp.data.data };
  } catch (error) {
    return { data: [] };
  }
};

export const GetTariffs = async () => {
  try {
    const resp = await instance.get("tariffs");
    return { data: resp.data.data };
  } catch (error) {
    return { data: [] };
  }
};

export const GetBonds = async () => {
  try {
    const resp = await instance.get("bonds");
    return { data: resp.data.data };
  } catch (error) {
    return { data: [] };
  }
};

export const GetDocs = async () => {
  try {
    const resp = await instance.get("docs");
    return { data: resp.data.data };
  } catch (error) {
    return { data: [] };
  }
};

export const GetExchangeRates = async () => {
  try {
    const resp = await instance.get("/market/rates");
    return { data: resp.data };
  } catch (error) {
    return { data: [] };
  }
};
