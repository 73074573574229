import React, { useEffect, useState, useRef } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import "./style.scss";
import Checked from "../../assets/Checked.svg";
import { GetBonds } from "../../api";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";

import Highcharts from "highcharts/highstock";
import { Fade } from "react-awesome-reveal";

const options = {
  title: {
    text: "",
  },
  accessibility: {
    enabled: false,
  },
  legend: {
    align: "left",
    verticalAlign: "bottom",
    floating: false,
    spacingTop: 50,
  },
  chart: {
    spacingLeft: -10,
    spacingBottom: 70,

    zooming: {
      key: "shift",
      type: "x",
      pinchType: "x",
      mouseWheel: {
        enabled: true,
        sensitivity: 1.1,
        type: "x",
      },
    },
    zoomKey: "shift",
    zoomType: "x",
  },
};

const ArmenianMarkets = () => {
  const [currency, setCurrency] = useState("AMD");
  const [corporateBonds, setCorporateBonds] = useState([]);
  const [govBonds, setGovBonds] = useState([]);
  const [corpData, setCorpData] = useState([]);
  const [corpDots, setCorpDots] = useState([]);
  const [govData, setGovData] = useState([]);
  const [govDots, setGovDots] = useState([]);

  useEffect(() => {
    GetBonds().then(({ data }) => {
      setCorporateBonds(data.corporate);
      setGovBonds(data.government);
    });
  }, []);
  useEffect(() => {
    if (corporateBonds.length) {
      setCorpData(
        corporateBonds
          .filter((b) => b.currency === currency)
          .map((b) => ({ x: +b.term, y: +b.yield_curve }))
      );
      setCorpDots(
        corporateBonds
          .filter((b) => b.currency === currency)
          .map((b) => ({
            x: +b.term,
            y: +b.yield,
            shortName: b.short_name, //.split(" (")[0],
            type: "corp",
          }))
      );
    } else {
      setCorpData([]);
      setCorpDots([]);
    }
  }, [corporateBonds, currency]);
  useEffect(() => {
    if (govBonds.length) {
      setGovData(
        govBonds
          .filter((b) => b.currency === currency)
          .map((b) => ({ x: +b.term, y: +b.yield_curve }))
      );
      setGovDots(
        govBonds
          .filter((b) => b.currency === currency)
          .map((b) => ({
            x: +b.term,
            y: +b.yield,
            shortName: b.short_name, //.split(" (")[0],
            type: "gov",
          }))
      );
    } else {
      setGovData([]);
      setGovDots([]);
    }
  }, [govBonds, currency]);

  const { t, i18n } = useTranslation();
  const chartRef = useRef(null);

  const handleZoomOut = () => {
    if (chartRef.current) {
      const chart = chartRef.current.chart;
      chart.zoomOut();
    }
  };

  useEffect(() => {
    Highcharts.setOptions({
      lang: {
        resetZoom: t("zoom"),
      },
    });
    handleZoomOut();
  }, [i18n.language]);

  return (
    <div className="armenianMarkets">
      <Fade
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        delay={600}
        direction="down"
        triggerOnce
      >
        <span style={{ textAlign: "center" }}>{t("armenianMarkets")}</span>
        <h1>{t("investInArmenianMarket")}</h1>
        <h2>{t("armenianMarketIsOneOf")}</h2>
      </Fade>

      <div className="graphics">
        <Fade delay={600} duration={2000} triggerOnce>
          <h3>{t("armenianBondsYieldCurve")}</h3>
          <p>{t("indicativeQuotes")}</p>
        </Fade>

        <div className="wrapper">
          <input
            checked={currency === "AMD"}
            onChange={(e) => {
              setCurrency(e.target.checked ? "AMD" : "USD");
              handleZoomOut();
            }}
            type="radio"
            name="select"
            id="option-1"
          />
          <input
            checked={currency === "USD"}
            onChange={(e) => {
              setCurrency(e.target.checked ? "USD" : "AMD");
              handleZoomOut();
            }}
            type="radio"
            name="select"
            id="option-2"
          />
          <label htmlFor="option-1" className="option option-1">
            <img className="dot" src={Checked} alt="Checked" />
            <span>AMD</span>
          </label>
          <label htmlFor="option-2" className="option option-2">
            <img className="dot" src={Checked} alt="Checked" />
            <span>USD</span>
          </label>
        </div>
        <span className="chartSpan chartSpan1">{t("rate")}</span>
        <HighchartsReact
          ref={chartRef}
          highcharts={Highcharts}
          constructorType={"chart"}
          options={{
            ...options,
            plotOptions: {
              series: {
                events: {
                  legendItemClick: function (e) {
                    e.preventDefault();
                  },
                },
              },
            },

            tooltip: {
              useHTML: true,
              headerFormat: "",
              pointFormat:
                `<span class="tooltipText">${t(
                  "shortName"
                )}: <span class="tooltipInfo">{point.shortName}</span></span>
                <br/><span class="tooltipText">${t("yield")}:
                <span class="tooltipInfo">{point.y}</span></span>` +
                `<br/><span class="tooltipText">${t(
                  "term"
                )}: <span class="tooltipInfo">{point.x}</span></span>`,
              style: {
                color: "rgba(43, 47, 56, 1)",
                fontSize: "14px",
              },
              padding: 16,
              valueDecimals: 2,
              backgroundColor: "rgba(242, 243, 245, 1)",
              borderRadius: 10,
            },

            series: [
              {
                type: "line",
                data: govData,
                color: "#ff1ead",
                showInLegend: false,
                marker: {
                  enabled: false,
                },
                states: {
                  hover: {
                    lineWidth: 0,
                  },
                },
                enableMouseTracking: false,
              },
              {
                type: "line",
                data: corpData,
                color: "#48a1e2",
                showInLegend: false,
                marker: {
                  enabled: false,
                },
                states: {
                  hover: {
                    lineWidth: 0,
                  },
                },
                enableMouseTracking: false,
              },
              {
                name: t("governmentBond"),
                type: "scatter",
                data: govDots,
                color: "#ff1ead",
                dashStyle: "Dot",
                marker: {
                  radius: 5,
                  symbol: "circle",
                },
              },
              {
                name: t("corporateBond"),
                type: "scatter",
                data: corpDots,
                color: "#48a1e2",
                dashStyle: "Solid",
                marker: {
                  radius: 5,
                  symbol: "circle",
                },
              },
            ],
          }}
        />
        <span className="chartSpan chartSpan2">{t("maturity")}</span>
      </div>
    </div>
  );
};

export default ArmenianMarkets;
