import React from "react";
import "./style.scss";
import Map from "../../assets/Map.svg";
import MapMobile from "../../assets/MapMobile.svg";
import Card from "../../components/globalMCard";
import BallBG2 from "../../assets/BallBG2.png";
import BallPY from "../../assets/BallPY.png";
import { useTranslation } from "react-i18next";
import { Fade } from "react-awesome-reveal";

const GlobalMarkets = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="armenianMarkets globalMarkets">
      <div className="backgroundBall">
        <img className="ball ball4-1" src={BallBG2} alt="img" />
        <img className="ball ball4-2" src={BallPY} alt="img" />
      </div>

      <Fade
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        delay={600}
        direction="down"
        triggerOnce
      >
        <span>{t("gloabalMarkets")}</span>
        <h1>{t("gatewayToMarkets")}</h1>
        <h2>{t("siriusTradingApp")}</h2>
      </Fade>

      <img className="mapImg" src={Map} alt="MapImg" />
      <img className="mapImg mapImgMobile" src={MapMobile} alt="MapImg" />

      <div className="marketInfo">
        <Card number="1000" text={t("Stocks")} />
        <Card number="700" text={t("Bonds")} />
        <Card number="50" text={t("Countries")} />
      </div>
    </div>
  );
};

export default GlobalMarkets;
