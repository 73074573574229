import React, { useEffect, useState } from "react";
import "./style.scss";
import { NavLink } from "react-router-dom";
import Logo2 from "../../assets/Logo2.svg";
import LABC from "../../assets/LABC.svg";
import LCBA from "../../assets/LCBA.svg";
import LFininfo from "../../assets/LFininfo.png";
import LogoMobileFooter from "../../assets/LogoMobileFooter.svg";
import Phone from "../../assets/Phone.svg";
import AppStore from "../../assets/AppStore.svg";
import GooglePlay from "../../assets/GooglePlay.svg";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import AccordionIcon from "../../assets/AccordionIconFooter.svg";
import { useTranslation } from "react-i18next";
import { baseUrl } from "../../api/instance";

const langs = {
  en: "ENG",
  ru: "RUS",
  am: "ARM",
};

const getLang = (l) => {
  if (Object.keys(langs).includes(l)) {
    // @ts-ignore
    return langs[l];
  } else {
    return langs.en;
  }
};

const Footer = () => {
  const { t, i18n } = useTranslation();
  const lang = getLang(i18n.language);

  const currentDate = new Date();
  const day = currentDate.getDate().toString().padStart(2, "0");
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const year = currentDate.getFullYear();
  const lastUpdate = `${day}.${month}.${year}`;

  const [acordion1, setAcordion1] = useState(false);
  const [acordion2, setAcordion2] = useState(false);
  const [acordion3, setAcordion3] = useState(false);
  const [acordion4, setAcordion4] = useState(false);
  const Acordion1 = () => {
    setAcordion1(!acordion1);
  };
  const Acordion2 = () => {
    setAcordion2(!acordion2);
  };
  const Acordion3 = () => {
    setAcordion3(!acordion3);
  };
  const Acordion4 = () => {
    setAcordion4(!acordion4);
  };

  const handleLinkClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  return (
    <div className="footer">
      <div className="top">
        <NavLink className="navLinkF" to="/" onClick={handleLinkClick}>
          <img className="logo" src={Logo2} alt="Logo2" />
        </NavLink>
        <NavLink
          className="navLinkF logoMobileFooter"
          to="/"
          onClick={handleLinkClick}
        >
          <img src={LogoMobileFooter} alt="LogoMobile" />
        </NavLink>

        <div className="phoen-addres">
          <div>
            <img src={Phone} alt="Phone" />+ 374 12 300 900
          </div>
          <p>{t("address")}</p>
        </div>
      </div>
      <div className="bottom">
        <div className="links">
          <ul>
            <li>{t("startInvesting")}</li>
            <li>
              <NavLink
                className="navLinkF"
                to="/armenian-markets"
                onClick={handleLinkClick}
              >
                {t("armenianMarket")}
              </NavLink>
            </li>

            <li>
              <NavLink
                className="navLinkF"
                to="/global-markets"
                onClick={handleLinkClick}
              >
                {t("globalMarket")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className="navLinkF"
                to="/safekeeping"
                onClick={handleLinkClick}
              >
                {t("safeKeeping")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className="navLinkF"
                to="/advisory"
                onClick={handleLinkClick}
              >
                {t("advisory")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className="navLinkF"
                to="/brokerage"
                onClick={handleLinkClick}
              >
                {t("brokerage")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className="navLinkF"
                to="/exchange-rates"
                onClick={handleLinkClick}
              >
                {t("exchangeRates")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className="navLinkF"
                to="/research"
                onClick={handleLinkClick}
              >
                {t("research")}
              </NavLink>
            </li>
          </ul>

          <ul>
            <li>{t("company")}</li>
            <li>
              <NavLink
                className="navLinkF"
                to="/about"
                onClick={handleLinkClick}
              >
                {t("aboutUs")}
              </NavLink>
            </li>
            <li>
              <NavLink
                className="navLinkF"
                to="/documents"
                onClick={handleLinkClick}
              >
                {t("documentsOld")}
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                className="navLinkF"
                to="/founding-documents"
                onClick={handleLinkClick}
              >
                {t("documents")}
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink
                className="navLinkF"
                to="/financial-statement"
                onClick={handleLinkClick}
              >
                {t("financialStatement")}
              </NavLink>
            </li> */}
            {/* <li className="legalActsD">
              {t("legalActs")}
              <ul className="legalActsDH">
                <NavLink
                  className="navLink"
                  to="/internal-acts"
                  onClick={handleLinkClick}
                >
                  {t("internalLActs")}
                </NavLink>
                <NavLink
                  className="navLink"
                  to="/normative-acts"
                  onClick={handleLinkClick}
                >
                  {t("normativeLActs")}
                </NavLink>
              </ul>
            </li> */}
            <li>
              <NavLink
                className="navLinkF"
                to="/news"
                onClick={handleLinkClick}
              >
                {t("news")}
              </NavLink>
            </li>
          </ul>

          <ul>
            <li>{t("plans")}</li>
            <li>
              <NavLink
                className="navLinkF"
                to="/tariffs"
                onClick={handleLinkClick}
              >
                {t("tariffs")}
              </NavLink>
            </li>
          </ul>

          <ul>
            <li>{t("contactUs")}</li>
            <li>
              <NavLink
                className="navLinkF"
                to="/contact"
                onClick={handleLinkClick}
              >
                {t("contactUs")}
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                className="navLinkF"
                to="/clients-rights"
                onClick={handleLinkClick}
              >
                {t("clientsRights")}
              </NavLink>
            </li> */}
          </ul>
        </div>

        <div className="accordion">
          <Accordion allowMultiple transition transitionTimeout={250}>
            <AccordionItem
              className="accordionItem"
              header={
                <div className="header" onClick={Acordion1}>
                  <p>{t("startInvesting")}</p>
                  <div className={!acordion1 ? "close" : "close open"}>
                    <img src={AccordionIcon} alt="accordionImg" />
                  </div>
                </div>
              }
            >
              <ul className="menuUl">
                <li>
                  <NavLink
                    onClick={handleLinkClick}
                    className="navLink"
                    to="/armenian-markets"
                  >
                    {t("armenianMarket")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={handleLinkClick}
                    className="navLink"
                    to="/global-markets"
                  >
                    {t("globalMarket")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={handleLinkClick}
                    className="navLink"
                    to="/safekeeping"
                  >
                    {t("safeKeeping")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={handleLinkClick}
                    className="navLink"
                    to="/advisory"
                  >
                    {t("advisory")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={handleLinkClick}
                    className="navLink"
                    to="/brokerage"
                  >
                    {t("brokerage")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={handleLinkClick}
                    className="navLink"
                    to="/exchange-rates"
                    
                  >
                    {t("exchangeRates")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={handleLinkClick}
                    className="navLink"
                    to="/research"
                  >
                    {t("research")}
                  </NavLink>
                </li>
              </ul>
            </AccordionItem>

            <AccordionItem
              className="accordionItem"
              header={
                <div className="header" onClick={Acordion3}>
                  <p>{t("company")} </p>
                  <div className={!acordion3 ? "close" : "close open"}>
                    <img src={AccordionIcon} alt="accordionImg" />
                  </div>
                </div>
              }
            >
              <ul className="menuUl">
                <li>
                  <NavLink
                    onClick={handleLinkClick}
                    className="navLink"
                    to="/about"
                  >
                    {t("aboutUs")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="navLink"
                    to="/documents"
                    onClick={handleLinkClick}
                  >
                    {t("documentsOld")}
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    onClick={handleLinkClick}
                    className="navLink"
                    to="/founding-documents"
                  >
                    {t("documents")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={handleLinkClick}
                    className="navLink"
                    to="/financial-statement"
                  >
                    {t("financialStatement")}
                  </NavLink>
                </li>
                <li className="legalActsD">
                  {t("legalActs")}
                  <ul className="legalActsDH">
                    <NavLink
                      onClick={handleLinkClick}
                      className="navLink"
                      to="/internal-acts"
                    >
                      {t("internalLActs")}
                    </NavLink>
                    <NavLink
                      onClick={handleLinkClick}
                      className="navLink"
                      to="/normative-acts"
                    >
                      {t("normativeLActs")}
                    </NavLink>
                  </ul>
                </li> */}
                <li>
                  <NavLink
                    onClick={handleLinkClick}
                    className="navLink"
                    to="/news"
                  >
                    {t("news")}
                  </NavLink>
                </li>
              </ul>
            </AccordionItem>

            <AccordionItem
              className="accordionItem"
              header={
                <div className="header" onClick={Acordion2}>
                  <p>{t("plans")} </p>
                  <div className={!acordion2 ? "close" : "close open"}>
                    <img src={AccordionIcon} alt="accordionImg" />
                  </div>
                </div>
              }
            >
              <ul className="menuUl">
                <li>
                  <NavLink
                    onClick={handleLinkClick}
                    className="navLink"
                    to="/tariffs"
                  >
                    {t("tariffs")}
                  </NavLink>
                </li>
              </ul>
            </AccordionItem>

            <AccordionItem
              className="accordionItem"
              header={
                <div className="header" onClick={Acordion4}>
                  <p>{t("contactUs")} </p>
                  <div className={!acordion4 ? "close" : "close open"}>
                    <img src={AccordionIcon} alt="accordionImg" />
                  </div>
                </div>
              }
            >
              <ul className="menuUl">
                <li>
                  <NavLink
                    onClick={handleLinkClick}
                    className="navLink"
                    to="/contact"
                  >
                    {t("contactUs")}
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    onClick={handleLinkClick}
                    className="navLink"
                    to="/clients-rights"
                  >
                    {t("clientsRights")}
                  </NavLink>
                </li> */}
              </ul>
            </AccordionItem>
          </Accordion>
        </div>

        <div className="usefulReferences">
          {/* <span>Useful references</span> */}
          <div>
            <a className="minRef" target="_blank" href="http://www.cba.am/">
              <img src={LCBA} alt="LCBA" />
            </a>
            <a
              className="minRef"
              target="_blank"
              href="https://www.fininfo.am/"
            >
              <img src={LFininfo} alt="LFininfo" />
            </a>
            <a
              className="longRef"
              target="_blank"
              href="https://www.abcfinance.am"
            >
              <img src={LABC} alt="LABC" />
            </a>

            <div className="downloadFromStore">
              <a
                target="_blank"
                href="https://apps.apple.com/am/app/sirius-capital/id6475756282"
              >
                <img src={AppStore} alt="AppStore" />
              </a>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.siriuscapital"
              >
                <img src={GooglePlay} alt="GooglePlay" />
              </a>
            </div>
          </div>
        </div>

        <div className="footerWarnings">
          <p>{t("languageWarning")}</p>
          <p>{t("referenceWarning")}</p>
        </div>

        <div className="contacts">
          <div className="icons">
            <a
              href="https://m.facebook.com/profile.php/?id=61552174452496"
              target="_blank"
            ></a>
            <a
              href="https://www.instagram.com/siriusarmenia?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              target="_blank"
            ></a>
            <a
              href="https://am.linkedin.com/company/siriuscap"
              target="_blank"
            ></a>
            <a href="https://t.me/siriusarmenia" target="_blank"></a>
          </div>
          <div className="copyright-links">
            <span>
              © Copyright {year}. {t("copyright")}
            </span>
            <a
              target="_blank"
              href={`${baseUrl}documents/Privacy policy ${lang}.pdf`}
            >
              <span>{t("privacyPolicy")}</span>
            </a>
            <span>
              {t("lastUpdate")} - {lastUpdate}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
