import React, { useEffect, useState } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import ResearchCard from "../../components/researchCard";
import { GetMembers, GetResearch } from "../../api";
import Loading from "../../components/loading";
import { Fade } from "react-awesome-reveal";
import { format, parse } from "date-fns";

const Research = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [researchData, setResearchData] = useState([]);

  const [members, setMembers] = useState([]);

  useEffect(() => {
    GetMembers().then(({ data }) => {
      setMembers(data);
    });

    GetResearch()
      .then(({ data }) => {
        if (data && data.length) {
          setResearchData(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="research">
      <Fade delay={600} direction="down" triggerOnce>
        <span style={{ textAlign: "center" }}>{t("research")}</span>
        <h1>{t("researchInfo")}</h1>
      </Fade>

      <div className="researchContainer">
        {researchData.length > 0 ? (
              researchData.map((item, index) => {
            const date = parse(item.date, "yyyy.MM.dd", new Date());
            const formattedDate = format(date, "d MMMM yyyy");

            return (
              <ResearchCard
                key={index}
                title={item.title}
                subtitle={item.subtitle}
                category={item.category.name}
                date={formattedDate}
                image={item.background_image}
                authors={members.filter((m) => item.authors.includes(m.id))}
                alias={item.alias}
              />
            );
          })
        ) : (
          <p style={{ width: "100%", textAlign: "center" }}>
            Research not found!
          </p>
        )}
      </div>
    </div>
  );
};

export default Research;
