import React from "react";
import "./style.scss";
import { Fade } from "react-awesome-reveal";

const Card = ({ title, downloadUrl }) => {
  return (
    <div
      className="documentCard"
      onClick={() => {
        window.open(downloadUrl, "_blank");
      }}
    >
      <Fade delay={600} triggerOnce>
        <div className="documentImg"></div>
      </Fade>
      <Fade delay={600} direction="down" triggerOnce>
        <p>{title}</p>
      </Fade>
    </div>
  );
};

export default Card;
