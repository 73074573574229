import React, { useEffect, useState, useMemo } from "react";
import "./style.scss";
import VectorDown from "../../assets/VectorDown.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Fade } from "react-awesome-reveal";
import { GetExchangeRates } from "../../api";

const appUrl = process.env.REACT_APP_BASE_URL_OF_APP;

const ExchangeRates = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [amount, setAmount] = useState("");
  const [equivalent, setEquivalent] = useState("");
  const [exchangeRates, setExchangeRates] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [currencyToList, setCurrencyToList] = useState([]);
  const [selectedToCurrency, setSelectedToCurrency] = useState("");

  const [lastChange, setLastChange] = useState("from");

  useEffect(() => {
    GetExchangeRates().then(({ data }) => {
      if (data) {
        setExchangeRates(data);
        setCurrencyList(
          Array.from(
            new Set([
              ...data.map((c) => c.tradeCurrencyCode),
              ...data.map((c) => c.currencyCode),
            ])
          )
        );
        setSelectedCurrency(data[0].tradeCurrencyCode);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedCurrency && exchangeRates) {
      const reverserList = Array.from(
        new Set([
          ...exchangeRates
            .filter((c) => c.tradeCurrencyCode === selectedCurrency)
            .map((c) => c.currencyCode),
          ...exchangeRates
            .filter((c) => c.currencyCode === selectedCurrency)
            .map((c) => c.tradeCurrencyCode),
        ])
      );

      setCurrencyToList(reverserList);
      setSelectedToCurrency(reverserList[0]);
    }
  }, [exchangeRates, selectedCurrency]);

  const rate = useMemo(() => {
    return (
      exchangeRates.find(
        (r) =>
          r.tradeCurrencyCode === selectedCurrency &&
          r.currencyCode === selectedToCurrency
      ) || null
    );
  }, [selectedCurrency, selectedToCurrency]);

  const buyRate = useMemo(() => {
    return (
      exchangeRates.find(
        (r) =>
          r.tradeCurrencyCode === selectedToCurrency &&
          r.currencyCode === selectedCurrency
      ) || null
    );
  }, [selectedCurrency, selectedToCurrency]);

  const onAmountEnter = (e) => {
    const strValue = String(e.target.value);
    const value = +strValue || "";
    setLastChange("from");
    setAmount(value);
  };
  const onequivalentEnter = (e) => {
    const strValue = String(e.target.value);
    const value = +strValue || "";
    setLastChange("to");
    setEquivalent(value);
  };

  useEffect(() => {
    if (
      (amount || equivalent) &&
      (rate || buyRate) &&
      exchangeRates.length > 0
    ) {
      // console.log("> --------------------------- <");
      // console.log(
      //   lastChange +
      //     " - " +
      //     (rate ? "rate" : "x") +
      //     "/" +
      //     (buyRate ? "buyRate" : "x") +
      //     " - " +
      //     selectedCurrency +
      //     " / " +
      //     selectedToCurrency
      // );
      if (rate) {
        if (lastChange === "to") {
          setAmount(+(equivalent / rate.sell).toFixed(4));
        } else {
          setEquivalent(+(amount * rate.sell).toFixed(4));
        }
      } else if (buyRate) {
        if (lastChange === "to") {
          setAmount(+(equivalent * buyRate.buy).toFixed(4));
        } else {
          setEquivalent(+(amount / buyRate.buy).toFixed(4));
        }
      } else {
        setEquivalent("");
        setAmount("");
      }
    } else {
      // console.log("<< - Clear - >>");
      setEquivalent("");
      setAmount("");
    }
  }, [rate, buyRate, amount, equivalent, lastChange]);

  return (
    <div className="exchangeRatePage">
      <div className="introduction">
        <Fade delay={600} direction="down" triggerOnce>
          <h1>{t("exchangeRates")}</h1>
          <h2>{t("scOffersExchangeRates")}</h2>
        </Fade>
      </div>
      <div className="exchangeRateContainer">
        <div className="exchangeRate">
          <div className="exchangeRateHeader">
            <Fade delay={600} direction="left" triggerOnce>
              <h1>{t("exchangeRateToday")}</h1>
            </Fade>
          </div>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>{t("buyEX")}</th>
                <th>{t("sellEX")}</th>
              </tr>
            </thead>
            <tbody>
              {exchangeRates.map((rate, index) => (
                <tr key={index}>
                  <td>{`${rate.tradeCurrencyCode}/${rate.currencyCode}`}</td>
                  <td>{rate.buy}</td>
                  <td>{rate.sell}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="exchangeRateCalculator">
          <div className="exchangeRateHeader">
            <Fade delay={800} direction="left" triggerOnce>
              <h1>{t("exchangeRateCalculator")}</h1>
            </Fade>
          </div>
          <div className="exchangeRateForm">
            <div className="exRateInput">
              <label htmlFor="have">{t("have")}</label>
              <input
                id="have"
                type={"number"}
                value={amount}
                onChange={onAmountEnter}
                min={0}
              />
            </div>
            <div className="exRateCalcDropdown">
              <p>{selectedCurrency}</p>
              <img src={VectorDown} alt="VectorDown" />

              <ul className="exRateCalcDropdownContent">
                {currencyList.map((c, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setLastChange("from");
                      setSelectedCurrency(c);
                    }}
                  >
                    {c}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="exchangeRateForm">
            <div className="exRateInput">
              <label htmlFor="need">{t("need")}</label>
              <input
                id="need"
                type={"number"}
                value={equivalent}
                onChange={onequivalentEnter}
                min={0}
              />
            </div>
            <div className="exRateCalcDropdown">
              <p>{selectedToCurrency}</p>
              <img src={VectorDown} alt="VectorDown" />

              <ul className="exRateCalcDropdownContent">
                {currencyToList.map((c, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setLastChange("to");
                      setSelectedToCurrency(c);
                    }}
                  >
                    {c}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExchangeRates;
