import React from "react";
import "./style.scss";
import PDF from "../../assets/PDF.svg";
import OpenLink from "../../assets/OpenLink.svg";

const Item = ({ text, downloadUrl, link }) => {
  const openLink = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div
      className="accordionItems"
      onClick={() => {
        !link ? window.open(downloadUrl, "_blank") : openLink(link);
      }}
    >
      <img
        className={link ? "linkImg" : undefined}
        src={!link ? PDF : OpenLink}
        alt="icon"
      />
      <p>{text}</p>
    </div>
  );
};

export default Item;
