import React, { useEffect, useState } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import NewsCard from "../../components/newsCard";
import { GetNews } from "../../api";
import { getMLValue } from "../../helpers/ml";
import { newsImgURL } from "../../helpers/image";
import Loading from "../../components/loading";
import { Fade } from "react-awesome-reveal";

const News = () => {
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [newsData, setNewsData] = useState([]);
  useEffect(() => {
    GetNews()
      .then(({ data }) => {
        if (data && data.length) {
          setNewsData(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="news">
      <Fade delay={600} direction="down" triggerOnce>
        <span style={{ textAlign: "center" }}>{t("news")}</span>
        <h1>{t("stayUpdated")}</h1>
      </Fade>

      <div className="newsContainer">
        {newsData.length > 0 ? (
          newsData.map((item, index) => (
            <NewsCard
              key={index}
              title={getMLValue(item.title, i18n.language)}
              content={getMLValue(item.description, i18n.language)}
              image={
                item.news_images[0]?.news_image &&
                newsImgURL(item.news_images[0]?.news_image)
              }
              date={item.date}
              alias={item.alias}
            />
          ))
        ) : (
          <p style={{ width: "100%", textAlign: "center" }}>News not found!</p>
        )}
      </div>
    </div>
  );
};

export default News;
