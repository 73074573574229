import { baseUrl } from "../api/instance";

export const serverImgURL = (fileName) => {
  return baseUrl + "uploads/" + (fileName || "default.webp");
};

export const galleryImgURL = (fileName) => {
  return baseUrl + "gallery/" + fileName;
};

export const newsImgURL = (fileName) => {
  return baseUrl + "news/image/" + fileName;
};
