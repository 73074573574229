import React from "react";
import "./style.scss";
import AdvisoryImg from "../../assets/AdvisoryImg.svg";
import Terms from "./../../components/terms";
import TermImg3 from "../../assets/TermImg3.svg";
import TermImg4 from "../../assets/TermImg4.svg";
import BallBG4 from "../../assets/BallBG4.png";
import BallPY from "../../assets/BallPY.png";
import { useTranslation } from "react-i18next";
import { Fade } from "react-awesome-reveal";

const Advisory = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="armenianMarkets safeKeeping advisory">
      <img className="ball ball6-1" src={BallBG4} alt="img" />
      <img className="ball ball6-2" src={BallPY} alt="img2" />

      <Fade
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        delay={600}
        direction="down"
        triggerOnce
      >
        <span>{t("AdvisoryAndAsset")}</span>
        <h1>{t("siriusPremium")}</h1>
      </Fade>

      <img className="imgAd" src={AdvisoryImg} alt="AdvisoryImg" />

      <div className="terms">
        <Terms
          img={TermImg3}
          title={t("getAdvice")}
          li1={t("ongoingMarket")}
          li2={t("consultingWithResearch")}
          li3={t("coverageOfdifferent")}
          li4={t("smartRisk")}
        />
        <Terms
          img={TermImg4}
          title={t("OurAdvisors")}
          li1={t("siriusIntelligent")}
          li2={t("standardizedModel")}
          li3={t("tailoredPortfolios")}
        />
      </div>
    </div>
  );
};

export default Advisory;
