import React, { useEffect, useState } from "react";
import "./styel.scss";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Author from "../../assets/DefaultAuthor1.svg";
import { GetMembers, GetResearchByAlias } from "../../api";
import { getMLValue } from "../../helpers/ml";
import { format, parse, isValid } from "date-fns";
import Loading from "../../components/loading";
import { galleryImgURL } from "../../helpers/image";
import { documentDownloadURL } from "../../helpers/doc";
import DocViewer, { PDFRenderer } from "@cyntler/react-doc-viewer";

const PDF = React.memo(
  ({ uri }) => {
    return (
      <DocViewer
        className="doc-viewer"
        documents={[{ uri, fileType: "pdf" }]}
        pluginRenderers={[PDFRenderer]}
        config={{
          header: {
            disableHeader: true,
            disableFileName: true,
          },
          pdfZoom: {
            defaultZoom: 1,
            zoomJump: 0.2,
          },
          pdfVerticalScrollByDefault: true,
        }}
      />
    );
  },
  (prev, next) => {
    return prev.uri === next.uri;
  }
);

const ResearchPage = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [researchData, setResearchData] = useState([]);
  const [formattedDate, setFormattedDate] = useState("");
  const [members, setMembers] = useState([]);

  useEffect(() => {
    GetMembers().then(({ data }) => {
      setMembers(data);
    });

    GetResearchByAlias(id)
      .then(({ data }) => {
        if (data) {
          const parsedDate = parse(data.date, "yyyy.MM.dd", new Date());
          if (isValid(parsedDate)) {
            setFormattedDate(format(parsedDate, "d MMMM yyyy"));
          }
          setResearchData(data);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  if (isLoading) {
    return <Loading />;
  }

  // {/* <Fade style={{ width: "80%" }} delay={400} direction="down" triggerOnce>
  //   <h1>{getMLValue(newsData.title, i18n.language)}</h1>
  //   <span>Story by Sirius Capital • {newsData.date}</span>
  // </Fade> */}

  return (
    <div className="researchPage">
      <div
        className="researchPageHeader"
        style={{
          backgroundImage: `url(${galleryImgURL(
            researchData.background_image
          )})`,
        }}
      >
        <div className="headerContent">
          <p>{getMLValue(researchData.category.name, i18n.language)}</p>
          <h1>{getMLValue(researchData.title || "", i18n.language)}</h1>
          <div className="headerBottomContent">
            <span>{formattedDate}</span>
            <a
              href={documentDownloadURL(
                getMLValue(researchData.pdf, i18n.language)
              )}
              download
              target="_blank"
            ></a>
          </div>
        </div>
      </div>
      <div className="researchPageContent">
        <div className="authors">
          {members
            .filter((m) => researchData.authors.includes(m.id))
            .map((member) => (
              <div key={member.id} className="authors-field">
                <img
                  src={member.image ? galleryImgURL(member.image) : Author}
                  alt={getMLValue(member.name, i18n.language) || "Author"}
                />
                <div>
                  <p>{getMLValue(member.name, i18n.language)}</p>
                  <span>{getMLValue(member.role, i18n.language)}</span>
                </div>
              </div>
            ))}
        </div>
        <p
          style={{
            width: "100%",
            maxWidth: "800px",
            paddingBlock: "32px",
            textAlign: "left",
          }}
        >
          {getMLValue(researchData.subtitle || "", i18n.language)}
        </p>

        <div className="researchPageMaterial">
          <PDF
            uri={documentDownloadURL(
              getMLValue(researchData.pdf, i18n.language)
            )}
          />
        </div>

        <div className="disclaimer">
          <a
            href={documentDownloadURL(
              getMLValue(researchData.disclaimer_link, i18n.language)
            )}
            download
          >
            {t("disclaimer")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ResearchPage;
