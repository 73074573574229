import React from "react";
import "./style.scss";
import BallPY from "../../assets/BallPY.png";
import Alert from "../../assets/Alert.svg";
import { useTranslation } from "react-i18next";
import { Fade } from "react-awesome-reveal";

const alertText = {
  en: `Dear Users,
  <br />
  <br />
  Taking into account the mass cases of actions carried out by phone fraudsters, "SIRIUS CAPITAL" CJSC considers it necessary to warn that our employees do not make phone calls in order to attract customers, and only people who are already customers of the company can receive phone calls from the following official phone number:
  <br />+ 374 12 300 900 <br />
  If you receive a call from "SIRIUS CAPITAL" CJSC from any other phone number, please contact us, and if you have already incurred damage as a result of fraudsters' actions, please contact law enforcement agencies.
  <br />
  <br />
  With respect
  "SIRIUS CAPITAL" CJSC`,
  am: `Հարգելի՛ օգտատերեր,
  <br />
  <br />
  Հեռախոսային զեղծարարների կողմից իրականացվող գործողությունների զանգվածային դեպքերը հաշվի առնելով, "ՍԻՐԻՈՒՍ ԿԱՊԻՏԱԼ" ՓԲ ընկերությունը հարկ է համարում զգուշացնել, որ մեր աշխատակիցները հեռախոսային զանգեր չեն իրականացնում հաճախորդներ ներգրավելու նպատակով, և միայն արդեն իսկ ընկերության հաճախորդ հանդիսացող անձինք կարող ենք հեռախոսազանգեր ստանալ հետևյալ պաշտոնական հեռախոսահամարից՝
  <br /> + 374 12 300 900  <br />
  Ցանկացած այլ հեռախոսահամարով "ՍԻՐԻՈՒՍ ԿԱՊԻՏԱԼ" ՓԲ ընկերության անունից զանգ ստանալու դեպքում խնդրում ենք կապ հաստատել Մեզ հետ, իսկ եթե արդեն իսկ վնաս եք կրել զեղծարարների գործողությունների արդյունքում, խնդրում ենք դիմել իրավապահ մարմիններին:  
  <br />  <br />
  Հարգանքով
  "ՍԻՐԻՈՒՍ ԿԱՊԻՏԱԼ" ՓԲԸ`,
  ru: `Уважаемые пользователи,
  <br />  <br />
  Принимая во внимание массовые случаи действий телефонных мошенников, компания ЗАО «СИРИУС КАПИТАЛ» считает необходимым предупредить, что наши сотрудники не совершают телефонные звонки с целью привлечения клиентов, и только люди, которые уже являются клиентами компании, могут получить телефонные звонки со следующего официального номера телефона:
  <br />+ 374 12 300 900  <br />
  Если вам позвонили от имени компании ЗАО «СИРИУС КАПИТАЛ» из любого другого номера телефона, свяжитесь с нами, а если вам уже был причинен ущерб в результате действий мошенников, обратитесь в правоохранительные органы.
  <br />  <br />
  С уважением
  ЗАО «СИРИУС КАПИТАЛ»`,
};

const ContactUS = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="contactUs">
      <img className="ball ball3-1" src={BallPY} alt="img1" />

      <div className="backgroundImage">{t("contactUsS")}</div>

      <div className="alert">
        <Fade delay={600} triggerOnce>
          <img src={Alert} alt="Alert" />
          <p dangerouslySetInnerHTML={{ __html: alertText[i18n.language] }} />
        </Fade>
      </div>
      <div className="contacts">
        <div className="contactsCard">
          <Fade direction="left" delay={600} triggerOnce>
            <p>{t("office")}</p>
            <span>{t("erebuniPlaza")}</span>
            <span>0010 {t("area")} 26/1, 812</span>
            <span>{t("pNumber")}: +374 12 300 900</span>
            <span>
              {t("mailAd")}:
              <a href="mailto:support@siriuscapital.am">
                requests@siriuscapital.am
              </a>
            </span>
          </Fade>
        </div>
        <div className="contactsCard">
          <Fade direction="left" delay={700} triggerOnce>
            <p>{t("customerSupport")}</p>
            <span>{t("pNumber")}: +374 12 300 900</span>
            <span>
              {t("mailAd")}:
              <a href="mailto:support@siriuscapital.am">
                support@siriuscapital.am
              </a>
            </span>
          </Fade>
        </div>
        <div className="contactsCard">
          <Fade direction="left" delay={800} triggerOnce>
            <p>{t("forInstitutionalClients")}</p>
            <span>{t("pNumber")}: +374 12 300 900</span>
            <span>
              {t("mailAd")}:
              <a href="mailto:support@siriuscapital.am">
                inst@siriuscapital.am
              </a>
            </span>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default ContactUS;
