import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  BrowserRouter,
  NavLink,
} from "react-router-dom";
import Home from "./pages/home";
import Navigation from "./components/nav";
import AboutUs from "./pages/aboutUs";
import Footer from "./components/footer";
import ContactUs from "./pages/contactUs";
import Documents from "./pages/documents";
import ArmenianMarkets from "./pages/armenianMarkets";
import GlobalMarkets from "./pages/globalMarkets";
import SafeKeeping from "./pages/safeKeeping";
import Advisory from "./pages/advisory";
import Brokerage from "./pages/brokerage";
import Tariffs from "./pages/tariffs";
import FinancialStatement from "./pages/financialStatement";
import News from "./pages/news";
import InternalActs from "./pages/internalActs";
import NormativeActs from "./pages/normativeActs";
import ClientsRights from "./pages/clientsRights";
import Cookies from "./components/cookies";
import NewsPage from "./pages/newsPage/index";
import DocumentsOld from "./pages/documentsOld";
import Research from "./pages/research";
import ResearchPage from "./pages/researchPage";
import ExchangeRates from "./pages/exchangeRates";

const App = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (cookiesAccepted) {
      setAcceptedCookies(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setAcceptedCookies(true);
  };
  return (
    <div className="app">
      {!acceptedCookies && (
        <Cookies
          onAccept={handleAcceptCookies}
          onReject={() => setAcceptedCookies(true)}
        />
      )}
      <BrowserRouter>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/armenian-markets" element={<ArmenianMarkets />} />
          <Route path="/global-markets" element={<GlobalMarkets />} />
          <Route path="/safekeeping" element={<SafeKeeping />} />
          <Route path="/advisory" element={<Advisory />} />
          <Route path="/brokerage" element={<Brokerage />} />
          <Route path="/exchange-rates" element={<ExchangeRates />} />

          <Route path="/about" element={<AboutUs />} />
          <Route path="/documents" element={<DocumentsOld />} />
          {/* _______ */}
          {/* <Route path="/founding-documents" element={<Documents />} /> */}
          {/* <Route path="/financial-statement" element={<FinancialStatement />} /> */}
          {/* <Route path="/internal-acts" element={<InternalActs />} /> */}
          {/* <Route path="/normative-acts" element={<NormativeActs />} /> */}
          {/* _______ */}

          <Route path="/news" element={<News />} />
          <Route path="/news/:id" element={<NewsPage />} />
          <Route path="/research" element={<Research />} />
          <Route path="/research/:id" element={<ResearchPage />} />

          <Route path="/tariffs" element={<Tariffs />} />

          <Route path="/contact" element={<ContactUs />} />

          {/* <Route path="/exchange-rates" element={<ExchangeRates />} /> */}

          {/* _______ */}
          {/* <Route path="/clients-rights" element={<ClientsRights />} /> */}
          {/* _______ */}
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
