import React from "react";
import "./style.scss";
import { Fade } from "react-awesome-reveal";

const TeamCard = ({ name, portrait, jobTitle, info }) => {
  return (
    <div className="teamCard">
      <div className="portrait">
        <Fade triggerOnce duration={2000}>
          <img src={portrait} alt="Portrait" />
        </Fade>
      </div>
      <Fade triggerOnce direction="left">
        <p>{name}</p>
        <span>{jobTitle}</span>
      </Fade>
      <Fade triggerOnce delay={100} direction="left">
        <span className="info">{info}</span>
      </Fade>
    </div>
  );
};

export default TeamCard;
